import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';
import Person from '../components/Person';

const Staff = props => {
  const title = 'Meet the Staff';
  const people = props.data.allTeamCsv.edges;

  return (
    <Layout title={title}>
      <div className="c-content-box c-size-md">
        <div className="container c-margin-t-40">
          <Title
            main={title}
            helper="Our office team is supported by a fabulous production team which is second to none! They ensure that the labels produced are of the high quality that Calf Hey is well known for."
          />
          <div className="row display-flex">
            {people.map(p => {
              const image = require(`../../assets/images/${p.node.image}`);

              return (
                <Person
                  key={p.node.id}
                  name={p.node.name}
                  image={image}
                  company={p.node.company}
                  position={p.node.position}
                  email={p.node.email}
                  bio={p.node.bio}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Staff;

export const query = graphql`
  {
    allTeamCsv {
      edges {
        node {
          id
          name
          image
          company
          position
          email
          bio
        }
      }
    }
  }
`;
